@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,600;1,500&family=Montserrat:wght@400;800&family=Roboto&display=swap');

body {
  font-family: 'Karla', sans-serif;
  background:#000;
}

* {
  box-sizing: border-box;
}

.app-area__sidebar {
  width: 550px;
  position: absolute;
  top: 0%;
  bottom: 0px;  
  padding: 32px;
  background:#000;
  background:#FFF;

  .logo {
    background-image:url(./logo.png);
    height: 48px;
    background-size: auto 100%;
    background-position: left center;
    background-repeat:no-repeat;
  }

  .livewall {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 32px;

    a {
      display:block;
      height: 40px;    
      margin-bottom: 16px;
      background-image:url(./livewall.png);
      background-size: auto 100%;
      background-repeat:no-repeat;
      background-position: center right;
      width: 100%;
    }
  }

  h1 {
   
    color:#FFF;
    
    line-height:1;
    font-weight: 600px;
    display:inline-block;
    margin:0;
    margin-bottom: 16px;

    span {
      color:#D4F21B;
    }
  }
  &__introduction {
    margin-top: 24px;
    margin-bottom: 32px;
    background:#FFF;
    color:#000;
    display:inline-block;
    padding: 4px;
  }

  input, select {
    padding: 16px;
    border-radius: 16px;
    display:block;
    width: 100%;
    color:#FFF;
    background: transparent ;
    border: 1px solid rgba(0,0,0,0.3);
    color:rgba(0,0,0,0.6);
  }

  .app-area__sidebar__form__field {
    margin-bottom: 16px;

    &__label {
      margin-bottom: 8px;
      font-weight:800;
      color:#333;
    }
  }

  .app-area__sidebar__error {
    color:#b54b7e;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .app-area__sidebar__form__field__hosts {
    display:flex;
  }
  .app-area__sidebar__form__field__hosts__host {
    width: 130px;
    border-radius: 20px;
    padding: 8px;
    text-align:center;

    &__image {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-size:cover;
      margin-left: auto;
      margin-right:auto;
      border: 2px solid transparent;
      cursor:pointer;
      margin-bottom: 16px;

      &.image1 {
        background-image: url(./host_zoe.jpg);
      }
      &.image2 {
        background-image: url(./host_mike.jpg);
      }
      &.image3 {
        background-image: url(./host_david.jpg);
      }

    }

    &--active {
      .image1, .image2,.image3 {
        border: 2px solid #b54b7e;
      }
    }
  }

  button {
    background-color: #b54b7e;
    font-family: 'Karla', sans-serif;
    color: #FFF;
    border:0;
    padding: 1em 2.4em 1em;
    font-size: 16px;
    border-radius: 16px;
  }


}

.app-area__video {
  position: absolute;
  left: 600px;
  right: 50px;
  top: 50%;
  transform:translate(0,-50%);

  &__holder {
    padding-bottom: 56.25%;
    position: relative;
  }

  video {
    position: absolute;
    top: 0px;
    left: 0px;
    display:block;
    background:#000;
    width: 100%;
    height: 100%;
  }

  &__status {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    color:#FFF;
    transform:translate(-50%,-50%);
    z-index:1;
    text-align:center;
    font-size: 24px;
  }

}

.app-area__sidebar__loading {
  width: 40px;
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Border color of the spinner */
    border-top: 4px solid #b54b7e; /* Color of the spinning animation */
    border-radius: 50%; /* Makes the border circular */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Apply the spinning animation */
    margin: 20px auto; /* Center the spinner */
  }

}
.app-area__sidebar__loading__bar {
  .progress-bar {
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 4px;
    
    &__fill {
      background:#b54b7e;
      border-radius: 4px;
      font-size: 11px;
      padding: 3px;
      color:#FFF;
      line-height:1;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); } /* Starting rotation */
  100% { transform: rotate(360deg); } /* Ending rotation after one full circle */
}